import React, { Suspense } from 'react';
import { Script } from 'gatsby';
import Preloader from '../components/common/Preloader';
import AppLayout from '../components/AppLayout';

const HomeLazy = React.lazy(() => import('../components/HomePage'));

function HomePage({ location, navigate }) {
  const handleScriptLoad = () => {
    if (typeof document !== "undefined") {   
      const chat = document.querySelector('#hubspot-messages-iframe-container');
      if (chat && !chat.classList.contains('hidden')) {
        chat.classList.add('hidden');
      } else {
        setTimeout(() => {
          const chat = document.querySelector('#hubspot-messages-iframe-container');
            if (chat && !chat.classList.contains('hidden')) {
              chat.classList.add('hidden');
            }
        }, 1000);
      }
    }
  }
  return (
    <div>
      <Suspense fallback={<Preloader />}>
        <AppLayout location={location} navigate={navigate}>
        <HomeLazy location={location} navigate={navigate} />
        </AppLayout>
      </Suspense>
      <Script type='text/javascript' id='hs-script-loader' async defer src='//js-na1.hs-scripts.com/22453439.js' onLoad={handleScriptLoad}></Script>
    </div>
  );
}
export default HomePage;

export const Head = () => (
  <>
    <title>Digital Marketing Agency Targetiva | Web Development, SEO, Paid Ads</title>
    <meta name="description" content="Crush goals with digital marketing agency Targetiva. Web Development, SEO, Data Science, Marketing Automation, Paid Advertising, Branding, Social Media Marketing, Content Production and more" /> 
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Digital Marketing Agency Targetiva",
          "alternateName": "Targetiva LCC",
          "url": "https://www.targetiva.com/",
          "logo": "https://www.targetiva.com/static/logo-black-cd763d8ad385ae699ee5b382d0157670.svg",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+17864088600",
            "contactType": "customer service",
            "areaServed": "US",
            "availableLanguage": "en"
          },
          "sameAs": [
            "https://ua.linkedin.com/company/targetiva-agency",
            "https://www.trustpilot.com/review/targetiva.com",
            "https://www.instagram.com/targetiva/"
          ]
      }
    `}
  </script>   
  </>
);
